<template>
    <div class="pagenotfound">
        <h1> {{ title }} </h1>
        <img :src="rand_img" style="max-width: 100%;max-height: 100%" />
        <br>
        I think one of us failed...
    </div>
</template>

<script>
import gifs from '@/assets/NotFoundGifs.json'

export default {
    name: 'pagenotfound',
    data () {
        return {
            title: '404 - Nope, not found'
        }
    },
    computed: {
        rand_img () {
            var pathPrefix = '/static/gifs/'
            var rand = Math.random() * (gifs.count - 1)
            var imgNu = rand.toFixed()
            var imgUrl = pathPrefix + gifs.gifs[imgNu]
            return imgUrl
        }
    },
}
</script>
